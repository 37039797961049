<template>
  <img
    v-if="icons[name].type === 'assets'"
    class="block align-middle"
    :style="`width: ${size || 'auto'}; min-width: ${size || 'auto'};`"
    :src="getIcon(`${icons[name].name}.${icons[name].extension}`)"
    :alt="icons[name].name"
  />

  <span
    v-else
    :class="['block align-middle', icons[name].type]"
    :style="`font-size: ${size || 'inherit'};`"
  >
    {{ icons[name].name }}
  </span>
</template>

<script setup lang="ts">
export type IconName =
  | "Add"
  | "Apps"
  | "ArrowDown"
  | "ArrowDropDown"
  | "ArrowDropUp"
  | "ArrowLeft"
  | "ArrowRight"
  | "Article"
  | "Calendar"
  | "Camera"
  | "Carousel"
  | "Category"
  | "ChartBar"
  | "Chat"
  | "Check"
  | "CheckCircle"
  | "Checklist"
  | "Click"
  | "Close"
  | "CreditCard"
  | "DarkMode"
  | "Delete"
  | "Email"
  | "Error"
  | "Facebook"
  | "Filter"
  | "GoBack"
  | "Image"
  | "Info"
  | "Instagram"
  | "LightMode"
  | "Linkedin"
  | "Lock"
  | "LockOpen"
  | "Logout"
  | "MasterCard"
  | "Menu"
  | "Modal"
  | "Numbers"
  | "Pagination"
  | "PasswordVisibility"
  | "PasswordVisibilityOff"
  | "Pencil"
  | "Sad"
  | "Search"
  | "Settings"
  | "ShoppingCart"
  | "Skeleton"
  | "SortBy"
  | "Success"
  | "Table"
  | "Text"
  | "Upload"
  | "Users"
  | "Visa";

import { getIcon } from "../utils";

withDefaults(
  defineProps<{
    name: IconName;
    size?: string;
  }>(),
  {
    size: undefined,
  }
);

type Icon = {
  [Key in IconName]: {
    name: string;
    type: "assets" | "material-icons" | "material-icons-outlined" | "material-icons-round";
    extension?: "svg" | "png";
  };
};

const icons: Icon = {
  Add: { name: "add", type: "material-icons" },
  Apps: { name: "apps", type: "material-icons" },
  ArrowDown: { name: "keyboard_arrow_down", type: "material-icons" },
  ArrowDropDown: { name: "arrow_drop_down", type: "material-icons" },
  ArrowDropUp: { name: "arrow_drop_up", type: "material-icons" },
  ArrowLeft: { name: "keyboard_arrow_left", type: "material-icons" },
  ArrowRight: { name: "keyboard_arrow_right", type: "material-icons" },
  Article: { name: "article", type: "material-icons" },
  Calendar: { name: "calendar_month", type: "material-icons" },
  Camera: { name: "camera_alt", type: "material-icons-outlined" },
  Carousel: { name: "view_carousel", type: "material-icons-outlined" },
  Category: { name: "category", type: "material-icons" },
  ChartBar: { name: "insert_chart", type: "material-icons" },
  Chat: { name: "chat_bubble", type: "material-icons" },
  Check: { name: "check", type: "material-icons" },
  CheckCircle: { name: "check_circle", type: "material-icons" },
  Checklist: { name: "checklist", type: "material-icons" },
  Click: { name: "ads_click", type: "material-icons" },
  Close: { name: "close", type: "material-icons" },
  CreditCard: { name: "credit_card", type: "material-icons" },
  DarkMode: { name: "dark_mode", type: "material-icons" },
  Delete: { name: "delete", type: "material-icons-outlined" },
  Email: { name: "email", type: "material-icons" },
  Error: { name: "error_outline", type: "material-icons-outlined" },
  Facebook: { name: "facebook", type: "assets", extension: "svg" },
  Filter: { name: "filter_list", type: "material-icons" },
  GoBack: { name: "arrow_back", type: "material-icons" },
  Image: { name: "image", type: "material-icons" },
  Info: { name: "info", type: "material-icons-outlined" },
  Instagram: { name: "instagram", type: "assets", extension: "svg" },
  LightMode: { name: "light_mode", type: "material-icons-outlined" },
  Linkedin: { name: "linkedin", type: "assets", extension: "svg" },
  Lock: { name: "lock", type: "material-icons" },
  LockOpen: { name: "lock_open", type: "material-icons" },
  Logout: { name: "logout", type: "material-icons" },
  MasterCard: { name: "master_card", type: "assets", extension: "svg" },
  Menu: { name: "menu", type: "material-icons" },
  Modal: { name: "domain_verification", type: "material-icons" },
  Numbers: { name: "123", type: "material-icons" },
  Pagination: { name: "code", type: "material-icons-outlined" },
  PasswordVisibility: { name: "visibility", type: "material-icons-outlined" },
  PasswordVisibilityOff: { name: "visibility_off", type: "material-icons-outlined" },
  Pencil: { name: "edit", type: "material-icons-outlined" },
  Sad: { name: "sentiment_dissatisfied", type: "material-icons-outlined" },
  Search: { name: "search", type: "material-icons" },
  Settings: { name: "settings", type: "material-icons-outlined" },
  ShoppingCart: { name: "shopping_cart", type: "material-icons" },
  Skeleton: { name: "horizontal_split", type: "material-icons" },
  SortBy: { name: "swap_vert", type: "material-icons" },
  Success: { name: "check_circle", type: "material-icons-outlined" },
  Table: { name: "table_rows", type: "material-icons-outlined" },
  Text: { name: "text_snippet", type: "material-icons-outlined" },
  Upload: { name: "upload", type: "material-icons" },
  Users: { name: "people", type: "material-icons" },
  Visa: { name: "visa", type: "assets", extension: "png" },
};

// https://marella.me/material-icons/demo/
</script>
