import { AxiosRequestConfig } from "axios";

import { apiPost } from "./_server";
import { LoginRequest, ResetPasswordRequest, Session, SignUpRequest } from "./models/auth";

export async function signUp(payload: SignUpRequest): Promise<Session> {
  return apiPost({ path: "auth/signup", data: payload });
}

export async function login(payload: LoginRequest): Promise<Session> {
  return apiPost({ path: "auth/login", data: payload });
}

export async function logout(config?: AxiosRequestConfig): Promise<void> {
  return apiPost({ path: "auth/logout", config });
}

export async function requestPasswordReset(email: string): Promise<void> {
  return apiPost({ path: "auth/request_password_reset", data: { email } });
}

export async function validatePasswordResetCode(passwordResetCode: string): Promise<void> {
  return apiPost({ path: "auth/validate_password_reset_code", data: { passwordResetCode } });
}

export async function resetPassword(payload: ResetPasswordRequest): Promise<void> {
  return apiPost({ path: "auth/reset_password", data: payload });
}
