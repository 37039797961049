import { format, parse, parseISO } from "date-fns";
import { isArray } from "lodash";

import { isObject } from "./object";

export function formatToDate(value: Date) {
  return format(value, "dd/MM/yyyy");
}

export function formatToDatetime(value: Date) {
  return format(value, "dd/MM/yyyy HH:mm");
}

function executeFunctionOnAllObjectLayers(
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  callback: (value: any) => any,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  object: Record<string, any>,
  fields: string[]
) {
  if (!object) {
    return;
  }

  Object.entries(object).forEach(([key, value]) => {
    if (value !== null && (isArray(value) || isObject(value))) {
      if (isArray(value)) {
        value.forEach((element) => {
          if (typeof element === "object") {
            executeFunctionOnAllObjectLayers(callback, element, fields);
          }
        });
      } else {
        executeFunctionOnAllObjectLayers(callback, value, fields);
      }
    } else {
      if (fields.includes(key)) {
        object[key] = callback(value);
      }
    }
  });

  return object;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function convertDateStringToISOInObject(object: Record<string, any>, fields: string[]) {
  return executeFunctionOnAllObjectLayers(
    (value: string) => {
      if (value === "") {
        return null;
      } else {
        return parse(value, "dd/MM/yyyy", new Date());
      }
    },
    object,
    fields
  );
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function convertISOToDateInObject(object: Record<string, any>, fields: string[]) {
  return executeFunctionOnAllObjectLayers(
    (value: string | null) => {
      if (value !== null) {
        return parseISO(value);
      }
    },
    object,
    fields
  );
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function convertISOToDateStringInObject(object: Record<string, any>, fields: string[]) {
  return executeFunctionOnAllObjectLayers(
    (value: string | null) => {
      if (value === null) {
        return "";
      } else {
        return format(parseISO(value), "dd/MM/yyyy");
      }
    },
    object,
    fields
  );
}
