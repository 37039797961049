import { RouteRecordRaw } from "vue-router";

const routes: RouteRecordRaw[] = [
  {
    path: "/",
    component: () => import("../components/LayoutNavbarHorizontalAndFooter.vue"),
    children: [
      {
        path: "/",
        component: () => import("../views/user/Home.vue"),
      },
    ],
  },
  {
    path: "/cadastro",
    name: "Cadastro",
    component: () => import("../views/user/SignUp.vue"),
  },
  {
    path: "/",
    component: () => import("../components/LayoutNavbarVertical.vue"),
    children: [
      {
        path: "minha-conta",
        name: "Minha conta",
        component: () => import("../views/user/MyAccount.vue"),
        meta: { requiresAuth: true, priority: 1 },
      },
    ],
  },
  {
    path: "/exemplos",
    component: () => import("../components/LayoutNavbarVertical.vue"),
    children: [
      {
        path: "icones",
        name: "Ícones",
        component: () => import("../views/user/examples/_Icon.vue"),
        meta: { requiresAuth: true, priority: 0 },
      },
      {
        path: "botoes",
        name: "Botões",
        component: () => import("../views/user/examples/_Button.vue"),
        meta: { requiresAuth: true },
      },
      {
        path: "toast",
        name: "Toast",
        component: () => import("../views/user/examples/_Toast.vue"),
        meta: { requiresAuth: true },
      },
      {
        path: "modal",
        name: "Modal",
        component: () => import("../views/user/examples/_Modal.vue"),
        meta: { requiresAuth: true },
      },
      {
        path: "fale-conosco",
        name: "Fale conosco",
        component: () => import("../views/user/examples/_ContactUsPrivate.vue"),
        meta: { requiresAuth: true },
      },
      {
        path: "datas",
        name: "Datas",
        component: () => import("../views/user/examples/_Date.vue"),
        meta: { requiresAuth: true },
      },
      {
        path: "numeros",
        name: "Números",
        component: () => import("../views/user/examples/_Number.vue"),
        meta: { requiresAuth: true },
      },
      {
        path: "selecao",
        name: "Seleção",
        component: () => import("../views/user/examples/_Select.vue"),
        meta: { requiresAuth: true },
      },
      {
        path: "inputs-de-texto",
        name: "Inputs de texto",
        component: () => import("../views/user/examples/_InputText.vue"),
        meta: { requiresAuth: true },
      },
      {
        path: "inputs-variados",
        name: "Inputs variados",
        component: () => import("../views/user/examples/_InputVarious.vue"),
        meta: { requiresAuth: true },
      },
      {
        path: "compra-segura",
        name: "Compra segura",
        component: () => import("../views/user/examples/_CreditCardPurchase.vue"),
        meta: { requiresAuth: true },
      },
      {
        path: "meus-cartoes",
        name: "Meus cartões",
        component: () => import("../views/user/examples/_MyCards.vue"),
        meta: { requiresAuth: true },
      },
      {
        path: "collapse",
        name: "Collapse",
        component: () => import("../views/user/examples/_Collapse.vue"),
        meta: { requiresAuth: true },
      },
      {
        path: "skeleton",
        name: "Skeletons",
        component: () => import("../views/user/examples/_Skeleton.vue"),
        meta: { requiresAuth: true },
      },
      {
        path: "paginacao",
        name: "Paginação",
        component: () => import("../views/user/examples/_Pagination.vue"),
        meta: { requiresAuth: true },
      },
      {
        path: "tabelas",
        name: "Tabelas",
        component: () => import("../views/user/examples/_Table.vue"),
        meta: { requiresAuth: true },
      },
      {
        path: "carrosseis",
        name: "Carroséis",
        component: () => import("../views/user/examples/_Carousels.vue"),
        meta: { requiresAuth: true },
      },
    ],
  },
];

export default routes;
