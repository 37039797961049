<template>
  <button
    :type="type"
    :disabled="disabled || isLoading"
    :class="[
      classes.base,
      classes.variant[variant],
      {
        [classes.disabledBase]: disabled,
        [classes.disabled[variant]]: disabled,
        [classes.hover[variant]]: !disabled && !isLoading,
      },
    ]"
    @click.prevent="emit('click')"
  >
    <LoadingSpinner
      v-if="isLoading"
      :color="classes.loadingColor[props.variant]"
      :size="loadingSize"
      :stroke="loadingStroke"
    />

    <div v-else class="flex items-center">
      <Icon v-if="iconOnLeftSide" class="mr-2" :name="iconOnLeftSide" :size="iconSize" />
      <slot />
      <Icon v-if="iconOnRightSide" class="ml-2" :name="iconOnRightSide" :size="iconSize" />
    </div>
  </button>
</template>

<script setup lang="ts">
import Icon, { IconName } from "./Icon.vue";
import LoadingSpinner, { ColorsName } from "./LoadingSpinner.vue";

export type ButtonVariant =
  | "elevated"
  | "outlined"
  | "outlinedError"
  | "outlinedLight"
  | "dashed"
  | "text";

const props = withDefaults(
  defineProps<{
    type?: "submit" | "button" | "reset";
    variant?: ButtonVariant;
    disabled?: boolean;
    isLoading?: boolean;
    loadingSize?: number;
    loadingStroke?: number;
    loadingColor?: ColorsName;
    iconOnRightSide?: IconName;
    iconOnLeftSide?: IconName;
    iconSize?: string;
  }>(),
  {
    type: "submit",
    variant: "elevated",
    disabled: false,
    isLoading: false,
    loadingSize: 20,
    loadingStroke: 3,
    loadingColor: undefined,
    iconOnRightSide: undefined,
    iconOnLeftSide: undefined,
    iconSize: undefined,
  }
);

interface Classes {
  base: string;
  disabledBase: string;
  variant: { [Key in ButtonVariant]: string };
  hover: { [Key in ButtonVariant]: string };
  disabled: { [Key in ButtonVariant]: string };
  loadingColor: { [Key in ButtonVariant]: ColorsName };
}

const classes: Classes = {
  base: "flex items-center justify-center transition ease-in-out duration-200 min-h-button w-full max-w-button p-2",
  disabledBase: "opacity-50 cursor-not-allowed",
  variant: {
    elevated: "bg-primary text-white rounded",
    outlined: "border-2 border-primary text-primary rounded",
    outlinedError: "border-2 border-error text-error rounded",
    outlinedLight: "border-2 border-white text-white rounded",
    dashed: "border-2 border-dashed border-primary text-primary rounded",
    text: "text-primary",
  },
  hover: {
    elevated: "hover:opacity-75",
    outlined: "hover:opacity-75",
    outlinedError: "hover:opacity-75",
    outlinedLight: "hover:bg-white hover:text-primary",
    dashed: "hover:opacity-75",
    text: "hover:opacity-75",
  },
  disabled: {
    elevated: "",
    outlined: "",
    outlinedError: "",
    outlinedLight: "",
    dashed: "",
    text: "",
  },
  loadingColor: {
    elevated: props.loadingColor ?? "white",
    outlined: props.loadingColor ?? "primary",
    outlinedError: props.loadingColor ?? "error",
    outlinedLight: props.loadingColor ?? "white",
    dashed: props.loadingColor ?? "primary",
    text: props.loadingColor ?? "primary",
  },
};

const emit = defineEmits<{ (event: "click"): void }>();
</script>
